import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import DraftProtection from '../components/DraftProtection'
import { Comments } from '@hyvor/hyvor-talk-react'

const components = {
  p: props => {
    const text = props.children?.toString() || ''
    
    // Handle iframe video embeds (YouTube, etc.)
    if (text.startsWith('[video](') && text.endsWith(')')) {
      const iframeMatch = text.match(/\[video\]\(([\s\S]*?)\)/)
      if (iframeMatch && iframeMatch[1]) {
        return (
          <div className="relative w-full my-6 aspect-video">
            <div dangerouslySetInnerHTML={{ __html: iframeMatch[1].trim() }} />
          </div>
        )
      }
    }
    
    // Handle MP4 videos
    if (text === 'mp4-video') {
      // This handles the case where MDX converts [mp4-video](/path/to/video.mp4) to a link
      // Find the next sibling which should be the actual link
      const videoSrc = props.children?.props?.href
      if (videoSrc) {
        return (
          <div className="relative w-full my-6">
            <video 
              className="w-full" 
              controls 
              preload="metadata"
              playsInline
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      }
    }
    
    // Also keep the original format handler for backward compatibility
    if (text.startsWith('[mp4-video](') && text.endsWith(')')) {
      const videoMatch = text.match(/\[mp4-video\]\(([\s\S]*?)\)/)
      if (videoMatch && videoMatch[1]) {
        const videoSrc = videoMatch[1].trim()
        return (
          <div className="relative w-full my-6">
            <video 
              className="w-full" 
              controls 
              preload="metadata"
              playsInline
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      }
    }
    
    return <p {...props} />
  },
  a: ({ children, ...props }) => (
    <a {...props} className="text-indigo-600 hover:text-indigo-800">
      {children}
    </a>
  )
}

const PostTemplate = ({ data, location }) => {
  const { frontmatter, body } = data.mdx
  
  const content = (
    <article>
      <Link
        to="/"
        className="text-indigo-600 hover:text-indigo-800 mb-8 block"
      >
        ← Back to Home
      </Link>
      {frontmatter.externalUrl && (
        <div className="mb-8 text-sm text-gray-600">
          Originally posted on{' '}
          <a
            href={frontmatter.externalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-800"
          >
            {new URL(frontmatter.externalUrl).hostname}
          </a>
        </div>
      )}
      <h1 className="text-3xl font-bold mb-2">{frontmatter.title}</h1>
      <div className="text-sm text-gray-600 mb-8">[{frontmatter.date}]</div>
      <div className="prose prose-sm max-w-none [&_.caption]:text-center [&_.caption]:text-sm [&_.caption]:text-gray-600 [&_.caption]:-mt-4 [&_.caption]:mb-6">
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </div>
      {frontmatter.comments && frontmatter.comments === 'hyvortalk' ? (
        <div className="mt-8 pt-8 border-t border-gray-200">
          <Comments website-id={process.env.GATSBY_HYVOR_TALK_WEBSITE_ID} page-id={frontmatter.slug} />
        </div>
      ) : frontmatter.comments && (
        <div className="mt-8 pt-8 border-t border-gray-200">
          <a
            href={frontmatter.comments}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-800"
          >
            Comment on Hacker News
          </a>
        </div>
      )}
    </article>
  )
  return (
    <Layout location={location}>
      <Helmet
        title={frontmatter.title}
        link={[
          { rel: 'icon', href: '/bc-notes.png' }
        ]}
        meta={[
          { name: 'title', content: frontmatter.title },
          { name: 'description', content: frontmatter.subtitle || frontmatter.title },
          
          // Open Graph / Facebook
          { property: 'og:type', content: 'article' },
          { property: 'og:url', content: `https://ericmigi.com/blog/${frontmatter.slug}` },
          { property: 'og:title', content: frontmatter.title },
          { property: 'og:description', content: frontmatter.subtitle || frontmatter.title },
          { property: 'og:image', content: `https://ericmigi.com${frontmatter.socialImage ? frontmatter.socialImage : '/indexsocial.png'}` },
          { property: 'og:image:width', content: '1200' },
          { property: 'og:image:height', content: '630' },
          
          // Twitter
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:url', content: `https://ericmigi.com/blog/${frontmatter.slug}` },
          { name: 'twitter:title', content: frontmatter.title },
          { name: 'twitter:description', content: frontmatter.subtitle || frontmatter.title },
          { name: 'twitter:image', content: `https://ericmigi.com${frontmatter.socialImage ? frontmatter.socialImage : '/indexsocial.png'}` },
          { name: 'twitter:image:alt', content: frontmatter.title }
        ].filter(() => frontmatter.type === 'blog')}
      />
      <div className="max-w-xl">
        {frontmatter.draft ? (
          <DraftProtection>{content}</DraftProtection>
        ) : (
          content
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        type
        subtitle
        slug
        socialImage
        externalUrl
        comments
        draft
      }
    }
  }
`

export default PostTemplate
